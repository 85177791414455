import React, { useState, useEffect } from 'react';
// utils
// import axios from '../utils/axios';

const initialState = {
	client: {},
	products: [],
	productsIsLoading: true,
	shop: {},
	settings: {
		notice: "",
		coming_soon: false,
		coming_soon_text: "Coming soon"
	}
};

const BackendContext = React.createContext({
	...initialState
})

export const BackendProvider = ({ client, children }) => {
	const [products, setProducts] = useState(initialState.products);
	const [productsIsLoading, setProductsIsLoading] = useState(initialState.productsIsLoading);
	const [shop, setShop] = useState(initialState.shop);
	const [settings] = useState(initialState.settings);

	useEffect(() => {
		const fetchProducts = async () => {
			setProductsIsLoading(true);

			client.product.fetchAll().then(products => {
				console.log("ShopifyProvider fetchProducts", products)

				setTimeout(() => {
					setProducts(products);
					setProductsIsLoading(false);
				}, 1000)
			})
		}

		const fetchShop = async () => {
			client.shop.fetchInfo().then(shop => {
				console.log("ShopifyProvider fetchShop", shop)
				setShop(shop);
			})
		}

		// const fetchSettings = async () => {
		// 	const response = await axios.get('/setting');
		// 	const settings = response.data?.data?.attributes || initialState.settings;
		// 	console.log("ShopifyProvider fetchSettings", response, settings);
		// 	setSettings(settings);
		// }

		fetchProducts();
		fetchShop();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BackendContext.Provider
			value={{
				client,
				products,
				productsIsLoading,
				shop,
				settings
			}}
		>
			{children}
		</BackendContext.Provider>
	)
}

export default BackendContext