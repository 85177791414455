import React, { useState, useEffect } from 'react';

const initialState = {
	checkout: {
		lineItems: []
	},
	checkoutIsLoading: false
};

const ShopifyCheckoutContext = React.createContext({
	...initialState,
	addToCart: () => Promise.resolve(),
	removeFromCart: () => Promise.resolve(),
})

export const ShopifyCheckoutProvider = ({ client, children }) => {
	const [checkout, setCheckout] = useState(initialState.checkout);
	const [checkoutIsLoading, setCheckoutIsLoading] = useState(initialState.checkoutIsLoading);

	useEffect(() => {
		const create = async () => {
			client.checkout.create().then(checkout => {
				console.log("CheckoutProvider create", checkout)
				setCheckout(checkout);
			})
		}

		create();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addToCart = async (variantId, customAttributes) => {
		setCheckoutIsLoading(true);

		// const lineItem = checkout.lineItems?.find(lineItem => lineItem.variant.id === variantId);

		// if (lineItem) {
		// 	const quantity = (lineItem.quantity || 0) + 1;
		// 	const lineItemsToUpdate = [{
		// 		id: lineItem.id,
		// 		quantity: quantity,
		// 		customAttributes
		// 	}];

		// 	await client.checkout.updateLineItems(checkout.id, lineItemsToUpdate).then(checkout => {
		// 		console.log("CheckoutProvider addToCart", checkout)
		// 		setCheckout(checkout);
		// 		setCheckoutIsLoading(false)
		// 	})
		// } else {
			const lineItemsToAdd = [{
				variantId,
				quantity: 1,
				customAttributes
			}];

			await client.checkout.addLineItems(checkout.id, lineItemsToAdd).then(checkout => {
				console.log("CheckoutProvider addToCart", checkout)
				setCheckout(checkout);
				setCheckoutIsLoading(false)
			})
		// }
	}

	const removeFromCart = async (lineItemId) => {
		const lineItem = checkout.lineItems?.find(lineItem => lineItem.id === lineItemId);

		if (lineItem) {
			const quantity = Math.max((lineItem.quantity || 0) - 1, 0);
			const lineItemsToUpdate = [{ id: lineItemId, quantity: quantity }];

			client.checkout.updateLineItems(checkout.id, lineItemsToUpdate).then(checkout => {
				console.log("CheckoutProvider addToCart", checkout)
				setCheckout(checkout);
			})
		}
	}

	return (
		<ShopifyCheckoutContext.Provider
			value={{
				checkout,
				checkoutIsLoading,
				addToCart,
				removeFromCart
			}}
		>
			{children}
		</ShopifyCheckoutContext.Provider>
	)
}

export default ShopifyCheckoutContext