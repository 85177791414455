// ----------------------------------------------------------------------

export default function Input(theme) {
	return {
		// MuiInputBase: {
		// 	styleOverrides: {
		// 		root: {
		// 			...theme.typography.body2,
		// 			padding: theme.spacing(0.5, 3),
		// 			borderRadius: '100px',
		// 			minHeight: 54,
		// 			backgroundColor: theme.palette.background.highlight,
		// 			color: theme.palette.text.primary,
		// 			border: '1px solid #00000033',
		// 		}
		// 	}
		// }
		// MuiInputBase: {
		// 	styleOverrides: {
		// 		root: {
		// 			'&.Mui-disabled': {
		// 				'& svg': { color: theme.palette.text.disabled }
		// 			}
		// 		},
		// 		input: {
		// 			'&::placeholder': {
		// 				opacity: 1,
		// 				fontWeight: 500,
		// 				color: theme.palette.text.disabled
		// 			}
		// 		}
		// 	}
		// },
		// MuiInput: {
		// 	styleOverrides: {
		// 		underline: {
		// 			'&:before': {
		// 				borderBottomColor: theme.palette.grey[500_56]
		// 			}
		// 		}
		// 	}
		// },
		// MuiInputLabel: {
		// 	styleOverrides: {
		// 		root: {
		// 			fontWeight: 500
		// 		}
		// 	}
		// },
		// MuiFilledInput: {
		// 	styleOverrides: {
		// 		root: {
		// 			backgroundColor: theme.palette.grey[500_12],
		// 			'&:hover': {
		// 				backgroundColor: theme.palette.grey[500_16]
		// 			},
		// 			'&.Mui-focused': {
		// 				backgroundColor: theme.palette.action.focus
		// 			},
		// 			'&.Mui-disabled': {
		// 				backgroundColor: theme.palette.action.disabledBackground
		// 			}
		// 		},
		// 		underline: {
		// 			'&:before': {
		// 				borderBottomColor: theme.palette.grey[500_56]
		// 			}
		// 		}
		// 	}
		// },
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					...theme.typography.body2,
					padding: theme.spacing(1.5, 3),
					borderRadius: 27,
					minHeight: 54,
					// border: '1px solid #00000033',

					color: theme.palette.text.primary,
					backgroundColor: theme.palette.background.highlight,
					transition: 'color 0.2s ease, background-color 0.2s ease',

					'& .MuiSvgIcon-root': {
						color: theme.palette.text.primary,
						transition: 'color 0.2s ease',
					},

					'&.Mui-focused': {
						color: theme.palette.secondary.contrastText,
						backgroundColor: theme.palette.secondary.main,

						'& .MuiSvgIcon-root': {
							color: theme.palette.secondary.contrastText,
						}
					},




					'& .MuiOutlinedInput-notchedOutline': {
						border: '1px solid #00000033'
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						'& textarea, & .MuiSvgIcon-root': {
							color: '#fff !important',
						},
						border: '1px solid #00000033',
						// backgroundColor: theme.palette.secondary.main,

					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline textarea, &.Mui-focused .MuiOutlinedInput-notchedOutline .MuiSvgIcon-root': {
						color: '#fff !important',
					},
					// 	border: '1px solid #00000033',
					// 	backgroundColor: theme.palette.secondary.main,

					// },

					// '&.Mui-disabled': {
					// 	'& .MuiOutlinedInput-notchedOutline': {
					// 		borderColor: theme.palette.action.disabledBackground,
					// 		borderWidth: '1px !important'
					// 	}
					// },
					// '&:hover .MuiOutlinedInput-notchedOutline': {
					// 	borderColor: `${theme.palette.text.primary} !important`,
					// },
					// '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					// 	borderColor: `${theme.palette.primary.main} !important`,
					// },
				}
			}
		}
	};
}
