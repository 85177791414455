import React from 'react';
import ReactDOM from 'react-dom';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import Client from 'shopify-buy';
import { BackendProvider } from './contexts/BackendContext';
import { ShopifyCheckoutProvider } from './contexts/ShopifyCheckoutContext';

import App from './App';

const client = Client.buildClient({
	storefrontAccessToken: 'f696fe9332c904f297a9d46a5f772f3a',
	domain: 'vapaux-co.myshopify.com'
});

ReactDOM.render(
	<StrictMode>
		<HelmetProvider>
			<BackendProvider
				client={client}
			>
				<ShopifyCheckoutProvider
					client={client}
				>
					<App />
				</ShopifyCheckoutProvider>
			</BackendProvider>
		</HelmetProvider>
	</StrictMode>
	,
	document.getElementById('root')
);
