import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';

import { ProgressBarStyle } from './layouts';

import { ThemeModeProvider } from './contexts/ThemeModeContext';

const App = () => (
	<ThemeModeProvider>
		<ThemeConfig>
			<BrowserRouter>
				<ProgressBarStyle />
				<Router />
			</BrowserRouter>
		</ThemeConfig>
	</ThemeModeProvider>
)

export default App;
