import { useState, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import NProgress from 'nprogress';
// material
import { experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import { GlobalStyles } from '@material-ui/core'
// icons
// hooks
import useBackend from '../hooks/useBackend';
//
import NavigationBar from './NavigationBar';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
	display: 'flex',
	minHeight: '100%',
	overflow: 'hidden'
});

const MainStyle = styled('div')(() => ({
	flexGrow: 1,
	minHeight: '100%',
	maxWidth: '100%'
}));

// ----------------------------------------------------------------------

export function ProgressBarStyle() {
	const theme = useTheme();

	return (
		<GlobalStyles
			styles={{
				'#nprogress': {
					pointerEvents: 'none',
					'& .bar': {
						top: 0,
						left: 0,
						height: 5,
						width: '100%',
						position: 'fixed',
						zIndex: theme.zIndex.snackbar,
						backgroundColor: theme.palette.secondary.main,
					},
					'& .peg': {
						right: 0,
						opacity: 1,
						width: 100,
						height: '100%',
						display: 'block',
						position: 'absolute',
						transform: 'rotate(3deg) translate(0px, -4px)',
					}
				}
			}}
		/>
	);
}

function ProgressBar({ isFinished }) {
	NProgress.configure({
		showSpinner: false
	});

	useMemo(() => {
		NProgress.start();
	}, []);

	useEffect(() => {
		if (isFinished) NProgress.done();
	}, [isFinished]);

	return null;
}

export default function Layout() {
	const { products, productsIsLoading } = useBackend();
	const [open, setOpen] = useState(false);

	return (
		<RootStyle>
			<ProgressBar isFinished={products[0] || !productsIsLoading} />
			{productsIsLoading ? (
				<MainStyle>
					<LoadingScreen />
				</MainStyle>
			) : (
				<>
					<NavigationBar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
					<MainStyle>
						<Outlet />
					</MainStyle>
				</>
			)}
		</RootStyle>
	);
}