import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
	hidden: PropTypes.bool,
	sx: PropTypes.object
};

export default function Logo({ hidden = false, sx }) {
	const theme = useTheme();

	return (
		<Box sx={{
			...hidden && {
				opacity: 0
			},
			[theme.breakpoints.down('sm')]: {
				...hidden && {
					display: 'none'
				}
			},
			margin: theme.spacing(2.5, 0, 1),
			color: theme.palette.text.primary,
			...sx
		}}>
			<Typography variant="h1">
				valentina vapaux
			</Typography>
		</Box>
	);
}