// material
import { Stack, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import Logo from './Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.palette.background.primary
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
	return (
		<RootStyle {...other}>
			<Stack alignItems="center">
				<Logo />
				<Typography
					variant="body1"
					// sx={{ fontSize: "4rem" }}
				>
					{/* (⋈◍＞◡＜◍)。✧♡ */}
					{/* ☆ﾟ･*:.｡.☆†_(ﾟ▽ﾟ*)β☆.｡.:*･ﾟ☆ */}
					(⊃｡•́‿•̀｡)⊃━☆ﾟ.*･｡ﾟ
					{/* ʕ•ᴥ•ʔ */}
				</Typography>
			</Stack>
		</RootStyle>
	);
}
