import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// hooks
import useBackend from '../hooks/useBackend';
// layouts
import Layout from '../layouts';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
	return (
		<Suspense
			fallback={
				<LoadingScreen sx={{
					opacity: 0
				}} />
			}
		>
			<Component {...props} />
		</Suspense>
	);
};

export default function Router() {
	const { products } = useBackend();

	return useRoutes([
		...(products.length === 1 ? [
			{
				path: '/',
				element: <Layout />,
				children: [
					{ path: '/', element: <Product /> }
				]
			},
		] : [
			{
				path: '/',
				element: <Layout />,
				children: [
					{ path: '/', element: <Products /> },
					{ path: '/products/:productId', element: <Product /> }
				]
			}
		]),

		{
			path: '/',
			element: <Layout />,
			children: [
				{ path: '/cart', element: <Cart /> }
			]
		},

		{
			path: '/about',
			element: <Layout />,
			children: [
				{ path: '/', element: <About /> },
				{ path: '/:contentTag', element: <About /> },
			]
		},

		// Fallback Routes
		{
			path: '*',
			children: [
				{ path: '*', element: <Navigate to="/" replace /> }
			]
		}
	]);
}

// Lazily importing components

// -- Components
// const Home = Loadable(lazy(() => import('../pages/Home')));
const Product = Loadable(lazy(() => import('../pages/Product')));
const Products = Loadable(lazy(() => import('../pages/Products')));
const Cart = Loadable(lazy(() => import('../pages/Cart')));
const About = Loadable(lazy(() => import('../pages/About')));