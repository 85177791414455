// ----------------------------------------------------------------------

function pxToRem(value) {
	return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
	return {
		'@media (min-width:600px)': {
			fontSize: pxToRem(sm)
		},
		'@media (min-width:960px)': {
			fontSize: pxToRem(md)
		},
		'@media (min-width:1280px)': {
			fontSize: pxToRem(lg)
		}
	};
}

const FONT_HEADING = '"Gatlinburg", Georgia, serif' // Local font
const FONT_PRIMARY = '"Miller", Georgia, serif' // Local font
const FONT_SECONDARY = '"Alliance No.1", Helvetica, Arial, sans-serif' // Local font

const typography = {
	fontFamily: FONT_SECONDARY,
	fontWeightRegular: 400,
	fontWeightMedium: 400,
	fontWeightBold: 400,

	h1: {
		fontFamily: FONT_HEADING,
		textTransform: "lowercase",
		fontWeight: 400,
		fontSize: pxToRem(40),
		lineHeight: 0.8,
		letterSpacing: "-.015em",
		...responsiveFontSizes({ sm: 48, md: 64, lg: 72 })
	},

	h2: {
		fontFamily: FONT_PRIMARY,
		textTransform: "none",
		fontWeight: 400,
		fontSize: pxToRem(40),
		lineHeight: 1.17,
		letterSpacing: "-.0125em",
		...responsiveFontSizes({ sm: 40, md: 48, lg: 48 })
	},

	h3: {
		fontFamily: FONT_PRIMARY,
		textTransform: "lowercase",
		fontWeight: 400,
		fontSize: pxToRem(30),
		lineHeight: 1.2,
		letterSpacing: "-.01em",
		...responsiveFontSizes({ sm: 30, md: 30, lg: 30 })
	},

	h4: {
		fontFamily: FONT_PRIMARY,
		textTransform: "lowercase",
		fontWeight: 400,
		fontSize: pxToRem(24),
		lineHeight: 1.2,
		letterSpacing: "-.01em",
		...responsiveFontSizes({ sm: 24, md: 24, lg: 24 })
	},

	body1: {
		textTransform: "lowercase",
		fontWeight: 400,
		fontSize: pxToRem(18),
		lineHeight: 1.55,
		letterSpacing: "-.002em",
		...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
	},

	body2: {
		textTransform: "lowercase",
		fontWeight: 400,
		fontSize: pxToRem(16),
		lineHeight: 1.5,
		...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
	},

	caption: {
		textTransform: "uppercase",
		fontWeight: 400,
		fontSize: pxToRem(10),
		lineHeight: 1,
		...responsiveFontSizes({ sm: 10, md: 10, lg: 10 })
	}
};

export default typography;
