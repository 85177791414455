import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useCheckout from '../hooks/useCheckout';
// components
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(6px)',
	WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
	backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	minHeight: 64,
	// padding: theme.spacing(0, 0),
	padding: theme.spacing(0, 3),

	[theme.breakpoints.up('lg')]: {
		minHeight: 92,
		padding: theme.spacing(3, 5)
	}
}));

// ----------------------------------------------------------------------

export default function NavigationBar() {
	const theme = useTheme();

	const { checkout, checkoutIsLoading } = useCheckout();

	return (
		<RootStyle>
			<ToolbarStyle>
				<Button
					variant="text"
					disableRipple
					component={RouterLink}
					to="/"
					sx={{
						padding: 0
					}}
				>
					<Logo />
				</Button>

				<Box sx={{ flexGrow: 1 }} />

				{/*
					@todo kinda hacky way to reset the data. we should use react-router-dom in the future to redirect to "/" AND reset the form.
				*/}
				{ checkout?.lineItems?.[0] && (
					<LoadingButton
						loading={checkoutIsLoading}
						variant="contained"
						color="secondary"
						component={RouterLink}
						to="/cart"
						sx={{
							// padding: theme.spacing(1, 2),
							margin: theme.spacing(0, 0, 0, 2)
						}}
						// href={checkout.webUrl}
					>
						{`Warenkorb (${checkout?.lineItems?.reduce((partial_sum, lineItem) => partial_sum + lineItem.quantity, 0) || 0})`}
					</LoadingButton>
				)}
			</ToolbarStyle>
		</RootStyle>
	)
}