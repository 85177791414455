// ----------------------------------------------------------------------

export default function Button(theme) {
	return {
		MuiButton: {
			styleOverrides: {
				root: {
					...theme.typography.body2,
					padding: theme.spacing(0.5, 3),
					borderRadius: '100px',
					minHeight: 54,
					// minWidth: 30,
					// borderRadius: 0,
					cursor: 'pointer',
					boxShadow: 'none !important',
					'&:hover': {
						boxShadow: 'none'
					},
					textTransform: 'inherit',
					// '&.disabled': {
					// 	backgroundColor: `${theme.palette.grey[2]} !important`,
					// 	border: `1px solid ${theme.palette.grey[2]}`,
					// 	'&:hover': {
					// 		backgroundColor: theme.palette.grey[1],
					// 		border: `1px solid ${theme.palette.grey[1]}`,
					// 	}
					// }
					'&.Mui-disabled': {
						backgroundColor: "inherit",
						border: `1px solid ${theme.palette.grey[2]}`,
						'&:hover': {
							backgroundColor: theme.palette.grey[1],
							border: `1px solid ${theme.palette.grey[1]}`,
						}
					}
				},
				// contained: {
				// 	backgroundColor: alpha('#FFFFFF', 0.2),
				// 	borderRadius: '100px',
				// 	color: '#000000',
				// 	backdropFilter: 'blur(16px)',
				// 	WebkitBackdropFilter: 'blur(16px)', // Fix on Mobile,
				// 	'&:hover': {
				// 		backgroundColor: alpha(theme.palette.primary.main, 0.5),
				// 		boxShadow: 'none',
				// 		color: '#000000',
				// 	}
				// },
				containedPrimary: {
					backgroundColor: theme.palette.primary.main,
					border: `1px solid ${theme.palette.primary.main}`,
					'&:hover': {
						backgroundColor: theme.palette.primary.light,
						border: `1px solid ${theme.palette.primary.light}`,
					}
				},
				containedSecondary: {
					backgroundColor: theme.palette.secondary.main,
					border: `1px solid ${theme.palette.secondary.main}`,
					'&:hover': {
						backgroundColor: theme.palette.secondary.light,
						border: `1px solid ${theme.palette.secondary.light}`,
					}
				},
				// disabled: {
				// 	backgroundColor: `${theme.palette.grey[2]} !important`,
				// 	border: `1px solid ${theme.palette.grey[2]}`,
				// 	'&:hover': {
				// 		backgroundColor: theme.palette.grey[1],
				// 		border: `1px solid ${theme.palette.grey[1]}`,
				// 	}
				// },
				outlined: {
					backgroundColor: theme.palette.background.highlight,
					borderRadius: '100px',
					color: theme.palette.text.primary,
					border: 'thin solid #00000033',
					'&:hover': {
						backgroundColor: '#f3eadc',
						boxShadow: 'none',
						border: 'thin solid #00000033',
					}
				},
				text: {
					backgroundColor: 'transparent',
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}
				// containedPrimary: {
				// 	...theme.typography.overline,
				// 	textTransform: 'uppercase',

				// 	minWidth: 40,
				// 	backgroundColor: theme.palette.text.primary,
				// 	color: '#fff',

				// 	'&:hover': {
				// 		backgroundColor: theme.palette.text.primary
				// 	}
				// },
				// outlinedPrimary: {
				// 	...theme.typography.overline,
				// 	textTransform: 'uppercase',

				// 	minWidth: 40,
				// 	// backgroundColor: '#fff',
				// 	color: theme.palette.text.primary,
				// 	border: `1px solid ${theme.palette.text.primary}`,
				// },
				// outlined: {
				// 	// backgroundColor: '#fff',
				// 	padding: theme.spacing(0.5),
				// },
				// contained: {
				// 	// backgroundColor: theme.palette.text.primary,
				// 	// color: '#fff',
				// 	padding: theme.spacing(0.5),
				// },
				// text: {
				// 	backgroundColor: 'transparent',
				// 	'&:hover': {
				// 		backgroundColor: 'transparent'
				// 	}
				// }




				// sizeLarge: {
				// 	height: 48
				// },
				// // contained
				// containedInherit: {
				// 	color: theme.palette.grey[800],
				// 	// boxShadow: theme.customShadows.z8,
				// 	'&:hover': {
				// 		backgroundColor: theme.palette.grey[400]
				// 	}
				// },
				// // outlined
				// outlinedInherit: {
				// 	border: `1px solid ${theme.palette.grey[500_32]}`,
				// 	'&:hover': {
				// 		backgroundColor: theme.palette.action.hover
				// 	}
				// },
				// textInherit: {
				// 	'&:hover': {
				// 		backgroundColor: theme.palette.action.hover
				// 	}
				// }
			}
		}
	};
}