// import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// function createGradient(color1, color2) {
// 	return `linear-gradient(to bottom, ${color1}, ${color2})`;
// }

// SETUP COLORS
const GREY = {
	0: '#FFFFFF',
	1: '#EBEBEB',
	2: '#E4E4E4',
	3: '#D2D2D2'
};


const SECONDARY = {
	lighter: '#CAF5E4',
	light: '#5CC3AD',
	main: '#073937',
	dark: '#032329',
	darker: '#01131B',
	contrastText: '#fff'
}

const PRIMARY = {
	lighter: '#FDF6D2',
	// light: '#F7DB78',
	light: '#f9be25',
	main: '#E6B022',
	dark: '#A57411',
	darker: '#6E4506',
	contrastText: SECONDARY.main
}

const INFO = {
	lighter: '#FFF4E1',
	light: '#FFD7A4',
	main: '#FFAC69',
	dark: '#B76034',
	darker: '#7A2B14',
	contrastText: '#fff'
}

const COMMON = {
	common: { black: '#000', white: '#fff' },
	primary: { ...PRIMARY },
	secondary: { ...SECONDARY },
	info: { ...INFO },
	grey: GREY
};

const palette = {
	light: {
		...COMMON,
		text: { primary: SECONDARY.main, secondary: GREY[0] },
		background: { default: "#fffbf2", highlight: "#fcf5eb", shade: "#eaece2" }
	},
	dark: {
		...COMMON,
		text: { primary: SECONDARY.main, secondary: '#000' },
		background: { default: "#fffbf2", highlight: "#fcf5eb", shade: "#eaece2" }
	}
};

export default palette;
